// pag
import ApplicationListController from "./opggs/applicationList/components/Controller";
import applicationDetailsRouteBuilder from "./opggs/applicationDetails/applicationDetailsRouteBuilder";
import ApplicationAdhocUploadController from "./opggs/applicationAdhocUpload/components/Controller";
import DraftApplicationListController from "./opggs/draftApplicationList/components/Controller";
import opggsDraftApplicationDetailsRouteBuilder from "./opggs/draftApplicationDetails/draftApplicationDetailsRouteBuilder";
import LegislativeFormsListController from "./opggs/legislativeFormsList/components/Controller";
import TitleListController from "./opggs/titleList/components/TitlesListController";
import TitleDetailsController from "./opggs/titleDetails/components/TitleDetailsController";
import LegislativeFormsFormRequestCreateController from "./opggs/legislativeForms/formRequestCreate/components/Controller";
import LegislativeFormsFormRequestDetailsController from "./opggs/legislativeForms/formRequestDetails/components/Controller";
// Oei
import OeiDraftApplicationListController from "./oei/oeiDraftApplicationList/components/Controller";
import OeiApplicationListController from "./oei/oeiApplicationList/components/Controller";
import OeiAdhocUploadController from "./oei/oeiAdhocUpload/components/Controller";
import OeiInvitationToApplyController from "./oei/invitationToApplyList/components/Controller";
import oeiDraftApplicationDetailsRouteBuilder from "./oei/draftApplicationDetails/draftApplicationDetailsRouteBuilder";
import OeiLicenceListController from "./oei/licenceList/components/LicenceListController";
import LicenceDetailsController from "./oei/licenceDetails/components/LicenceDetailsController";
// user management
import CompanyListController from "./userManagement/companyList/components/CompanyListController";
import CompanyDetailsController from "./userManagement/companyDetails/components/CompanyDetailsController";
import UserListController from "./userManagement/userList/components/UserListController";
import UserDetailsController from "./userManagement/userDetails/components/UserDetailsController";
import RequestListController from "./userManagement/requestList/components/RequestListController";
import RequestDetailsController from "./userManagement/requestDetails/components/RequestDetailsController";
// finance
import FinancialNotificationListController from "./finance/financialNotificationList/components/Controller";
import FinancialNotificationDetailsController from "./finance/financialNotificationDetails/components/Controller";
import PaymentHistoryListController from "./finance/paymentHistoryList/components/Controller";
import PaymentHistoryDetailsController from "./finance/paymentHistoryDetails/components/Controller";
// my account
import RequestAccessController from "./account/requestAccess/components/RequestAccessController";
import MyDetailsController from "./account/myDetails/components/MyDetailsController";
// dashboard
import DashboardController from "./home/dashboard/components/Controller";
// common
import { IRouteItem, RouteBuilder } from "../infrastructure/routeItem";
import * as GlobalHelpers from "../common/GlobalHelpers";

const companyRouteBuilder: RouteBuilder = (isSignedIn: boolean) => {
    // useful for cheap feature switching
    const isProductionEnvironment = GlobalHelpers.isProductionEnvironment(); // eslint-disable-line @typescript-eslint/no-unused-vars
    let routes = new Array<IRouteItem>();

    // not authenticated? don't register routes
    if (!isSignedIn) return routes;

    // home
    routes.push({ path: "/Company/Home/Dashboard", exact: true, element: <DashboardController /> });

    // application
    routes.push({ path: "/Company/Opggs/ApplicationList", exact: true, element: <ApplicationListController /> });
    routes.push({ path: "/Company/Opggs/UploadTitleApplication", exact: true, element: <ApplicationAdhocUploadController /> });
    routes.push({ path: "/Company/Opggs/DraftApplicationList", exact: true, element: <DraftApplicationListController /> });

    // oei
    routes.push({ path: "/Company/Oei/ApplicationList", exact: true, element: <OeiApplicationListController /> });
    routes.push({ path: "/Company/Oei/DraftApplicationList", exact: true, element: <OeiDraftApplicationListController /> });
    routes.push({ path: "/Company/Oei/UploadLicenceApplication", exact: true, element: <OeiAdhocUploadController /> });
    routes.push({ path: "/Company/Oei/InvitationToApplyList", exact: true, element: <OeiInvitationToApplyController /> });
    routes.push({ path: "/Company/Oei/LicenceList", exact: true, element: <OeiLicenceListController /> });
    routes.push({ path: "/Company/Oei/LicenceDetails/:id/:tab?", exact: true, element: <LicenceDetailsController /> });

    // user management
    routes.push({ path: "/Company/User/CompanyList", exact: true, element: <CompanyListController /> });
    routes.push({ path: "/Company/User/CompanyDetails/:id", exact: true, element: <CompanyDetailsController /> });
    routes.push({ path: "/Company/User/UserList", exact: true, element: <UserListController /> });
    routes.push({ path: "/Company/User/UserDetails/:id", exact: true, element: <UserDetailsController /> });
    routes.push({ path: "/Company/User/RequestList", exact: true, element: <RequestListController /> });
    routes.push({ path: "/Company/User/RequestDetails/:type/:id", exact: true, element: <RequestDetailsController /> });

    // finance
    routes.push({ path: "/Company/Finance/FinancialNotificationList", exact: true, element: <FinancialNotificationListController /> });
    routes.push({ path: "/Company/Finance/PaymentHistoryList", exact: true, element: <PaymentHistoryListController /> });
    routes.push({ path: "/Company/Finance/FinancialNotificationDetails/:id", exact: true, element: <FinancialNotificationDetailsController /> });
    routes.push({ path: "/Company/Finance/PaymentHistoryDetails/:id", exact: true, element: <PaymentHistoryDetailsController /> });

    // legislative forms list
    routes.push({ path: "/Company/Opggs/LegislativeFormsList", exact: true, element: <LegislativeFormsListController /> });

    // titles
    routes.push({ path: "/Company/Opggs/TitleList", exact: true, element: <TitleListController /> });
    routes.push({ path: "/Company/Opggs/TitleDetails/:id/:tab?", exact: true, element: <TitleDetailsController /> });
    routes.push({ path: "/Company/Opggs/LegislativeForms/FormRequestCreate/:id", exact: true, element: <LegislativeFormsFormRequestCreateController /> });
    routes.push({ path: "/Company/Opggs/LegislativeForms/FormRequestDetails/:type/:id", exact: true, element: <LegislativeFormsFormRequestDetailsController /> });

    // my account
    routes.push({ path: "/Company/Account/MyDetails", exact: true, element: <MyDetailsController /> });
    routes.push({ path: "/Company/Account/RequestAccess", exact: true, element: <RequestAccessController /> });

    // application details
    routes = routes.concat(applicationDetailsRouteBuilder(isSignedIn));
    // draft application details
    routes = routes.concat(opggsDraftApplicationDetailsRouteBuilder(isSignedIn));
    routes = routes.concat(oeiDraftApplicationDetailsRouteBuilder(isSignedIn));

    return routes;
};

export default companyRouteBuilder;
