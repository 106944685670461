// api
import * as Client from "../api/Client";

// -  dashboard
export function dashboard(): string {
    return "/GeoscienceAustralia/Home/Dashboard";
}

// - wells/boreholes
export function wellList(): string {
    return "/GeoscienceAustralia/WellsBoreholes/WellList";
}

export function wellDetails(wellId: number): string {
    return `/GeoscienceAustralia/WellsBoreholes/WellDetails/${wellId}`;
}

export function wellBoreholes(wellId: number): string {
    return `/GeoscienceAustralia/WellsBoreholes/WellBoreholes/${wellId}`;
}

export function wellDataMonitoring(wellId: number): string {
    return `/GeoscienceAustralia/WellsBoreholes/WellDataMonitoring/${wellId}`;
}

export function wellDataMonitoringDetails(wellId: number, dataMonitoringItemId: number): string {
    return `/GeoscienceAustralia/WellsBoreholes/DataMonitoringDetails/${wellId}/${dataMonitoringItemId}`;
}

export function wellReports(wellId: number): string {
    return `/GeoscienceAustralia/WellsBoreholes/WellReports/${wellId}`;
}

export function wellReportAttachments(wellId: number): string {
    return `/GeoscienceAustralia/WellsBoreholes/WellReportAttachments/${wellId}`;
}

export function boreholeList(): string {
    return "/GeoscienceAustralia/WellsBoreholes/BoreholeList";
}

export function boreholeDetails(wellId: number, boreholeId: number): string {
    return `/GeoscienceAustralia/WellsBoreholes/BoreholeDetails/${wellId}/${boreholeId}`;
}

export function boreholeDataMonitoring(wellId: number, boreholeId: number): string {
    return `/GeoscienceAustralia/WellsBoreholes/BoreholeDataMonitoring/${wellId}/${boreholeId}`;
}

export function boreholeReports(wellId: number, boreholeId: number): string {
    return `/GeoscienceAustralia/WellsBoreholes/BoreholeReports/${wellId}/${boreholeId}`;
}

export function boreholeAttachments(wellId: number, boreholeId: number): string {
    return `/GeoscienceAustralia/WellsBoreholes/BoreholeReportAttachments/${wellId}/${boreholeId}`;
}

export function rigList(): string {
    return "/GeoscienceAustralia/WellsBoreholes/RigList";
}

export function rigDetails(rigId: number): string {
    return `/GeoscienceAustralia/WellsBoreholes/RigDetails/${rigId}`;
}

export function rigHistoryList(rigId: number): string {
    return `/GeoscienceAustralia/WellsBoreholes/RigHistoryList/${rigId}`;
}

export function reportList(): string {
    return "/GeoscienceAustralia/ReportList";
}

export function reportCreate(relatedActivityType?: Client.CreateReportRelatedActivityTypeEnum, relatedActivityId?: number | undefined): string {
    if (relatedActivityId) {
        return `/GeoscienceAustralia/ReportCreate/${relatedActivityType}/${relatedActivityId}`;
    } else {
        return `/GeoscienceAustralia/ReportCreate`;
    }
}

export function reportDetails(reportId: number): string {
    return `/GeoscienceAustralia/ReportDetails/${reportId}`;
}

export function reportFileAssets(reportId: number): string {
    return `/GeoscienceAustralia/ReportFileAssets/${reportId}`;
}

export function reportAttachments(reportId: number): string {
    return `/GeoscienceAustralia/ReportAttachments/${reportId}`;
}

export function reportRelatedActivities(reportId: number): string {
    return `/GeoscienceAustralia/ReportRelatedActivities/${reportId}`;
}

export function attachmentList(): string {
    return "/GeoscienceAustralia/AttachmentList";
}

// Survey Data
export function surveyDataList(): string {
    return "/GeoscienceAustralia/SurveyDataList";
}

export function surveyDataCreate(relatedActivityType?: Client.CreateSurveyDataRelatedActivityTypeEnum, relatedActivityId?: number | undefined): string {
    if (relatedActivityId) {
        return `/GeoscienceAustralia/SurveyDataCreate/${relatedActivityType}/${relatedActivityId}`;
    } else {
        return `/GeoscienceAustralia/SurveyDataCreate`;
    }
}

export function surveyDataDetails(surveyDataId: number): string {
    return `/GeoscienceAustralia/SurveyDataDetails/${surveyDataId}`;
}

export function surveyDataLines(surveyDataId: number): string {
    return `/GeoscienceAustralia/SurveyDataLines/${surveyDataId}`;
}

export function surveyDataFileAssets(surveyDataId: number): string {
    return `/GeoscienceAustralia/SurveyDataFileAssets/${surveyDataId}`;
}

export function surveyDataAttachments(surveyDataId: number): string {
    return `/GeoscienceAustralia/SurveyDataAttachments/${surveyDataId}`;
}

export function surveyDataRelatedActivities(surveyDataId: number): string {
    return `/GeoscienceAustralia/SurveyDataRelatedActivities/${surveyDataId}`;
}

// - surveys/acquisitions
export function surveyList(): string {
    return "/GeoscienceAustralia/SurveysAcquisitions/SurveyList";
}

export function surveyDetails(surveyId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/SurveyDetails/${surveyId}`;
}

export function surveyAcquisitions(surveyId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/SurveyAcquisitions/${surveyId}`;
}

export function surveyTitles(surveyId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/SurveyTitles/${surveyId}`;
}

export function surveyDataMonitoring(surveyId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/SurveyDataMonitoring/${surveyId}`;
}

export function surveyDataMonitoringDetails(surveyId: number, dataMonitoringItemId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/DataMonitoringDetails/${surveyId}/${dataMonitoringItemId}`;
}

export function surveyReports(surveyId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/SurveyReports/${surveyId}`;
}

export function surveyReportAttachments(surveyId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/SurveyReportAttachments/${surveyId}`;
}

export function surveySurveyData(surveyId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/SurveySurveyData/${surveyId}`;
}

export function acquisitionList(): string {
    return "/GeoscienceAustralia/SurveysAcquisitions/AcquisitionList";
}

export function acquisitionDetails(surveyId: number, acquisitionId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/AcquisitionDetails/${surveyId}/${acquisitionId}`;
}

export function acquisitionDataMonitoring(surveyId: number, acquisitionId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/AcquisitionDataMonitoring/${surveyId}/${acquisitionId}`;
}

export function acquisitionReports(surveyId: number, acquisitionId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/AcquisitionReports/${surveyId}/${acquisitionId}`;
}

export function acquisitionAttachments(surveyId: number, acquisitionId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/AcquisitionReportAttachments/${surveyId}/${acquisitionId}`;
}

export function acquisitionSurveyData(surveyId: number, acquisitionId: number): string {
    return `/GeoscienceAustralia/SurveysAcquisitions/AcquisitionSurveyData/${surveyId}/${acquisitionId}`;
}

// - reprocessings/projects
export function reprocessingList(): string {
    return "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingList";
}

export function reprocessingDetails(reprocessingId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingDetails/${reprocessingId}`;
}

export function reprocessingProjects(reprocessingId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjects/${reprocessingId}`;
}

export function reprocessingTitles(reprocessingId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingTitles/${reprocessingId}`;
}

export function reprocessingAcquisitions(reprocessingId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingAcquisitions/${reprocessingId}`;
}

export function reprocessingDataMonitoring(reprocessingId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingDataMonitoring/${reprocessingId}`;
}

export function reprocessingDataMonitoringDetails(reprocessingId: number, dataMonitoringItemId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/DataMonitoringDetails/${reprocessingId}/${dataMonitoringItemId}`;
}

export function reprocessingReports(reprocessingId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingReports/${reprocessingId}`;
}

export function reprocessingAttachments(reprocessingId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingAttachments/${reprocessingId}`;
}

export function reprocessingProjectList(): string {
    return "/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectList";
}

export function reprocessingProjectDetails(reprocessingId: number, reprocessingProjectId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectDetails/${reprocessingId}/${reprocessingProjectId}`;
}

export function reprocessingProjectReports(reprocessingId: number, reprocessingProjectId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectReports/${reprocessingId}/${reprocessingProjectId}`;
}

export function reprocessingProjectAttachments(reprocessingId: number, reprocessingProjectId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectAttachments/${reprocessingId}/${reprocessingProjectId}`;
}

export function reprocessingProjectTitles(reprocessingId: number, reprocessingProjectId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectTitles/${reprocessingId}/${reprocessingProjectId}`;
}

export function reprocessingProjectAcquisitions(reprocessingId: number, reprocessingProjectId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectAcquisitions/${reprocessingId}/${reprocessingProjectId}`;
}

export function reprocessingProjectDataMonitoring(reprocessingId: number, reprocessingProjectId: number): string {
    return `/GeoscienceAustralia/ReprocessingsProjects/ReprocessingProjectDataMonitoring/${reprocessingId}/${reprocessingProjectId}`;
}

// - dataSubmissions
export function dataSubmissionDetails(dataSubmissionId: number): string {
    return `/GeoscienceAustralia/DataSubmissions/DataSubmissionDetails/${dataSubmissionId}`;
}

export function dataSubmissionDataMonitoring(dataSubmissionId: number): string {
    return `/GeoscienceAustralia/DataSubmissions/DataSubmissionDataMonitoring/${dataSubmissionId}`;
}

export function dataSubmissionResubmissions(dataSubmissionId: number): string {
    return `/GeoscienceAustralia/DataSubmissions/DataSubmissionResubmissions/${dataSubmissionId}`;
}

export function dataSubmissionResubmissionDetails(dataSubmissionId: number, resubmissionId: number): string {
    return `/GeoscienceAustralia/DataSubmissions/DataSubmissionResubmissionDetails/${dataSubmissionId}/${resubmissionId}`;
}

export function authorisationList(searchType?: Client.SearchAuthorisationsTypeEnum | undefined): string {
    return `/GeoscienceAustralia/DataSubmissions/AuthorisationList/${searchType ?? Client.SearchAuthorisationsTypeEnum.InProgress}`;
}

export function authorisationDetails(authorisationId: number): string {
    return `/GeoscienceAustralia/DataSubmissions/AuthorisationDetails/${authorisationId}`;
}

// - feedback
export function feedbackList(): string {
    return "/GeoscienceAustralia/Feedback/FeedbackList";
}

export function feedbackDetails(feedbackId: number): string {
    return `/GeoscienceAustralia/Feedback/FeedbackDetails/${feedbackId}`;
}

// - user management
export function requestList(): string {
    return "/GeoscienceAustralia/User/RequestList";
}

export function requestDetails(secureRequestId: number, secureRequestGroupType: Client.SecureRequestGroupTypeEnum): string {
    return `/GeoscienceAustralia/User/RequestDetails/${secureRequestGroupType}/${secureRequestId}`;
}

export function manageGeoscienceAustralia(): string {
    return "/GeoscienceAustralia/User/GeoscienceAustralia";
}

export function myUserList(): string {
    return "/GeoscienceAustralia/User/UserList";
}

export function userDetails(secureUserId: number): string {
    return `/GeoscienceAustralia/User/UserDetails/${secureUserId}`;
}

// - my account
export function myDetails(): string {
    return "/GeoscienceAustralia/Account/MyDetails";
}

export function requestAccess(): string {
    return "/GeoscienceAustralia/Account/RequestAccess";
}
