import * as Client from "./Client";
import * as UriHelper from "./uriHelper";
import msalAuthService from "../infrastructure/msalAuthService";

export async function createShellClient(): Promise<Client.ShellServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.ShellServiceClient(config, uri));
}

export async function createShellAnonymousClient(): Promise<Client.ShellAnonymousServiceClient> {
    return createAnonymousClient((config, uri) => new Client.ShellAnonymousServiceClient(config, uri));
}

export async function createCompanyHomeDashboardClient(): Promise<Client.CompanyHomeDashboardClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyHomeDashboardClient(config, uri));
}

export async function createCompanyOpggsApplicationListClient(): Promise<Client.CompanyOpggsApplicationListServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOpggsApplicationListServiceClient(config, uri));
}

export async function createCompanyOpggsApplicationDetailsClient(): Promise<Client.CompanyOpggsApplicationDetailsServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOpggsApplicationDetailsServiceClient(config, uri));
}

export async function createCompanyOpggsApplicationAdhocUploadClient(): Promise<Client.CompanyOpggsApplicationAdhocUploadServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOpggsApplicationAdhocUploadServiceClient(config, uri));
}

export async function createCompanyOpggsDraftApplicationListClient(): Promise<Client.CompanyOpggsDraftApplicationListServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOpggsDraftApplicationListServiceClient(config, uri));
}

export async function createCompanyCommonDraftApplicationDetailsClient(): Promise<Client.CompanyCommonDraftApplicationDetailsServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyCommonDraftApplicationDetailsServiceClient(config, uri));
}

export async function createCompanyOpggsCreateDraftApplicationClient(): Promise<Client.CompanyOpggsCreateDraftApplicationServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOpggsCreateDraftApplicationServiceClient(config, uri));
}

export async function createCompanyOpggsDraftApplicationDetailsClient(): Promise<Client.CompanyOpggsDraftApplicationDetailsServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOpggsDraftApplicationDetailsServiceClient(config, uri));
}

export async function createCompanyOeiCreateDraftApplicationClient(): Promise<Client.CompanyOeiCreateDraftApplicationServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOeiCreateDraftApplicationServiceClient(config, uri));
}

export async function createCompanyOeiDraftApplicationDetailsClient(): Promise<Client.CompanyOeiDraftApplicationDetailsServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOeiDraftApplicationDetailsServiceClient(config, uri));
}

export async function createCompanyOeiDraftApplicationListClient(): Promise<Client.CompanyOeiDraftApplicationListServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOeiDraftApplicationListServiceClient(config, uri));
}

export async function createCompanyAccountClient(): Promise<Client.CompanyAccountClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyAccountClient(config, uri));
}

export async function createCompanyUserManagementClient(): Promise<Client.CompanyUserManagementClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyUserManagementClient(config, uri));
}

export function createRegistrationAnonymousRequestService(): Client.RegistrationAnonymousRequestServiceClient {
    return createAnonymousClient((config, uri) => new Client.RegistrationAnonymousRequestServiceClient(config, uri));
}

export async function createCompanyOpggsTitleListClient(): Promise<Client.CompanyOpggsTitleListServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOpggsTitleListServiceClient(config, uri));
}

export async function createCompanyOpggsTitleDetailsClient(): Promise<Client.CompanyOpggsTitleDetailsServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOpggsTitleDetailsServiceClient(config, uri));
}

export async function createCompanyOpggsLegislativeFormsClient(): Promise<Client.CompanyOpggsLegislativeFormsServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOpggsLegislativeFormsServiceClient(config, uri));
}

export async function createCompanyOpggsLegislativeFormsListClient(): Promise<Client.CompanyOpggsLegislativeFormsListServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOpggsLegislativeFormsListServiceClient(config, uri));
}

export async function createCompanyFinanceClient(): Promise<Client.CompanyFinanceServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyFinanceServiceClient(config, uri));
}

export async function createCompayOeiApplicationListClient(): Promise<Client.CompanyOeiApplicationListServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOeiApplicationListServiceClient(config, uri));
}

export async function createCompanyOeiLicenceListClient(): Promise<Client.CompanyOeiLicenceListServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOeiLicenceListServiceClient(config, uri));
}

export async function createCompanyOeiLicenceDetailsClient(): Promise<Client.CompanyOeiLicenceDetailsServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOeiLicenceDetailsServiceClient(config, uri));
}

export async function createCompayOeiInvitationToApplyListClient(): Promise<Client.CompanyOeiInvitationToApplyListServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOeiInvitationToApplyListServiceClient(config, uri));
}

export async function createCompanyOeiOeiAdhocUploadClient(): Promise<Client.CompanyOeiOeiAdhocUploadServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.CompanyOeiOeiAdhocUploadServiceClient(config, uri));
}

export async function createJointAuthorityHomeDashboardClient(): Promise<Client.JointAuthorityHomeDashboardClient> {
    return createAuthenticatedClient((config, uri) => new Client.JointAuthorityHomeDashboardClient(config, uri));
}

export async function createJointAuthorityOpggsApplicationListClient(): Promise<Client.JointAuthorityOpggsApplicationListServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.JointAuthorityOpggsApplicationListServiceClient(config, uri));
}

export async function createJointAuthorityOpggsApplicationDetailsClient(): Promise<Client.JointAuthorityOpggsApplicationDetailsServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.JointAuthorityOpggsApplicationDetailsServiceClient(config, uri));
}

export async function createJointAuthorityOpggsTitleListClient(): Promise<Client.JointAuthorityOpggsTitleListServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.JointAuthorityOpggsTitleListServiceClient(config, uri));
}

export async function createJointAuthorityOeiLicenceListClient(): Promise<Client.JointAuthorityOeiLicenceListServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.JointAuthorityOeiLicenceListServiceClient(config, uri));
}

export async function createJointAuthorityOeiLicenceDetailsClient(): Promise<Client.JointAuthorityOeiLicenceDetailsServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.JointAuthorityOeiLicenceDetailsServiceClient(config, uri));
}

export async function createJointAuthorityAccountClient(): Promise<Client.JointAuthorityAccountClient> {
    return createAuthenticatedClient((config, uri) => new Client.JointAuthorityAccountClient(config, uri));
}

export async function createJointAuthorityUserManagementClient(): Promise<Client.JointAuthorityUserManagementClient> {
    return createAuthenticatedClient((config, uri) => new Client.JointAuthorityUserManagementClient(config, uri));
}

// export async function createGeoscienceAustraliaHomeDashboardClient(): Promise<Client.GeoscienceAustraliaHomeDashboardClient> {
//     return createAuthenticatedClient((config, uri) => new Client.GeoscienceAustraliaHomeDashboardClient(config, uri));
// }

export async function createGeoscienceAustraliaWellsBoreholesClient(): Promise<Client.GeoscienceAustraliaWellsBoreholesServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.GeoscienceAustraliaWellsBoreholesServiceClient(config, uri));
}

export async function createGeoscienceAustraliaDataSubmissionClient(): Promise<Client.GeoscienceAustraliaDataSubmissionServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.GeoscienceAustraliaDataSubmissionServiceClient(config, uri));
}

export async function createGeoscienceAustraliaAuthorisationsClient(): Promise<Client.GeoscienceAustraliaAuthorisationsServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.GeoscienceAustraliaAuthorisationsServiceClient(config, uri));
}

export async function createGeoscienceAustraliaReportClient(): Promise<Client.GeoscienceAustraliaReportServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.GeoscienceAustraliaReportServiceClient(config, uri));
}

export async function createGeoscienceAustraliaAttachmentClient(): Promise<Client.GeoscienceAustraliaAttachmentServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.GeoscienceAustraliaAttachmentServiceClient(config, uri));
}

export async function createGeoscienceAustraliaSurveyDataClient(): Promise<Client.GeoscienceAustraliaSurveyDataServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.GeoscienceAustraliaSurveyDataServiceClient(config, uri));
}

export async function createGeoscienceAustraliaSurveysAcquisitionsClient(): Promise<Client.GeoscienceAustraliaSurveysAcquisitionsServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.GeoscienceAustraliaSurveysAcquisitionsServiceClient(config, uri));
}

export async function createGeoscienceAustraliaReprocessingsProjectsClient(): Promise<Client.GeoscienceAustraliaReprocessingsProjectsServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.GeoscienceAustraliaReprocessingsProjectsServiceClient(config, uri));
}

export async function createGeoscienceAustraliaFeedbackClient(): Promise<Client.GeoscienceAustraliaFeedbackServiceClient> {
    return createAuthenticatedClient((config, uri) => new Client.GeoscienceAustraliaFeedbackServiceClient(config, uri));
}

export async function createGeoscienceAustraliaAccountClient(): Promise<Client.GeoscienceAustraliaAccountClient> {
    return createAuthenticatedClient((config, uri) => new Client.GeoscienceAustraliaAccountClient(config, uri));
}

export async function createGeoscienceAustraliaUserManagementClient(): Promise<Client.GeoscienceAustraliaUserManagementClient> {
    return createAuthenticatedClient((config, uri) => new Client.GeoscienceAustraliaUserManagementClient(config, uri));
}

async function createAuthenticatedClient<T extends Client.AuthorisedApiBase>(factory: (config: Client.IConfig, uri: string) => T): Promise<T> {
    // sign-in
    const accessToken = await msalAuthService.getAccessToken();

    // return client
    const uri: string = UriHelper.getServicesUri();
    const config: Client.IConfig = { getAuthorisation: () => accessToken };
    return factory(config, uri);
}

function createAnonymousClient<T extends Client.AuthorisedApiBase>(factory: (config: Client.IConfig, uri: string) => T): T {
    // return client
    const uri: string = UriHelper.getServicesUri();
    const config: Client.IConfig = { getAuthorisation: () => "" };
    return factory(config, uri);
}
