// framework
import { call, put, select } from "redux-saga/effects";
// redux
import * as Actions from "../actions";
import * as Models from "../../models/models";
import { ICompanyReduxState } from "../../../../companyReduxRegistration";
// api
import * as ClientFactory from "../../../../../api/clientFactory";
import * as CallHelper from "../../../../../api/callHelper";
import * as Client from "../../../../../api/Client";
// common
import * as LogHelper from "../../../../../common/LogHelper";
import * as shellHelper from "../../../../../common/shell/shellHelper";

const getRootViewModel = (state: ICompanyReduxState) => state.Company_Oei_OeiInvitationToApplyList;

export default function* initialise() {
    const vm: Models.IRootViewModel = yield select(getRootViewModel);

    try {
        yield put(shellHelper.createSetBusyAction());

        // the invitation to apply list
        const invitationToApplyListResponse: Client.GetCompanyOeiInvitationToApplyListResponseDto = yield call(async () => {
            const client = await ClientFactory.createCompayOeiInvitationToApplyListClient();
            return await CallHelper.simpleCall(() => client.getCompanyOeiInvitationToApplyList(new Client.GetCompanyOeiInvitationToApplyListRequestDto()));
        });

        // the reference data for the create screen
        const createDraftApplicationReferenceDataResponse: Client.GetCompanyOeiCreateDraftApplicationReferenceDataResponseDto = yield call(async () => {
            const client = await ClientFactory.createCompanyOeiCreateDraftApplicationClient();
            return await CallHelper.simpleCall(() => client.getCompanyOeiCreateDraftApplicationReferenceData(new Client.GetCompanyOeiCreateDraftApplicationReferenceDataRequestDto()));
        });

        yield put(
            Actions.actionFactory.sagaCompleted(vm.refreshInvitationsToApply(invitationToApplyListResponse).refreshCreateDraftApplicationReferenceData(createDraftApplicationReferenceDataResponse))
        );
    } catch (ex) {
        LogHelper.logError(ex);
    } finally {
        yield put(shellHelper.createClearBusyAction());
    }
}
