// framework
import { useHistory } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import Container from "react-bootstrap/Container";
// custom components
import NavBarSignIn from "../../../signIn/NavBarSignInController";
import NavBarMyAccount from "../../../signIn/NavBarMyAccountController";
import EnvironmentBadge from "./EnvironmentBadgeView";
import ExternalLink from "../../../../common/externalLink/ExternalLinkView";
import { useResizeDetector } from "react-resize-detector";
import { QuestionIcon } from "@primer/octicons-react";
// common
import { ExternalLinkEnum } from "../../../../api/Client";
import * as ConfigurationHelper from "../../../../common/shell/configurationHelper";

interface ITopNavProps {
    isSignedIn: boolean;
}

export default function TopNavView(props: ITopNavProps): React.ReactElement {
    const neatsPublicPortalUri = process.env.REACT_APP_NEATS_PUBLIC_PORTAL_URI;

    const history = useHistory();

    const { ref: topNavRef, height: topNavHeight } = useResizeDetector();

    return (
        <>
            <div ref={topNavRef} className="fixed-top">
                <Navbar bg="dark" variant="dark" expand="lg">
                    <Container fluid>
                        <Navbar.Brand className="px-0 py-2" href="/" onClick={goHome}>
                            <img src="/favicon.png" width="40rem" height="40rem" alt="NEATS logo" className="me-2" />
                            <span style={{ fontSize: 22 }}>NEATS Secure Portal</span>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <ExternalLink href={neatsPublicPortalUri} className="nav-item nav-link" style={navlinkStyle}>
                                    Public Portal
                                </ExternalLink>
                                <ExternalLink href="https://www.nopta.gov.au" className="nav-item nav-link" style={navlinkStyle}>
                                    NOPTA
                                </ExternalLink>
                                <ExternalLink href="https://www.ga.gov.au/nopims" className="nav-item nav-link" style={navlinkStyle}>
                                    NOPIMS
                                </ExternalLink>
                            </Nav>

                            <EnvironmentBadge className="me-2" />
                            <NavBarMyAccount className="btn btn-outline-warning me-2" />
                            <NavBarSignIn className="me-2" />
                            <Help />
                        </Navbar.Collapse>
                    </Container>
                </Navbar>

                <div className="bg-warning" style={{ height: "0.1rem" }}></div>
            </div>

            {/* create a non-floating body of text to ensure the div has height that corresponds to the actual rendered height of the floating navbar */}
            <div style={{ height: topNavHeight }}></div>
        </>
    );

    function goHome(e: any): boolean {
        // don't use a normal href="/" on NavBar.Brand or else it will dump the react state
        // keeping the href="/" on there as it works nicely from a UX perspective (it keeps styles and behaviours)
        e.preventDefault();
        history.push("/");
        return false;
    }
}

function Help(): React.ReactElement {
    return (
        <a href={ConfigurationHelper.usePortalLink(ExternalLinkEnum.NeatsSecurePortalGuidance)} className="btn btn-outline-warning" target="_blank" rel="noopener noreferrer" title="Help">
            <QuestionIcon size="medium" />
        </a>
    );
}

const navlinkStyle = {
    fontSize: 18,
    border: "0.1rem solid transparent",
    color: "white",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
};
