// dashboard
import DashboardController from "./home/dashboard/components/Controller";
// common
import { IRouteItem, RouteBuilder } from "../infrastructure/routeItem";
import * as GlobalHelpers from "../common/GlobalHelpers";
// opggs
import ApplicationListController from "./opggs/applicationList/components/Controller";
import TitleListController from "./opggs/titleList/components/TitlesListController";
import applicationDetailsRouteBuilder from "./opggs/applicationDetails/applicationDetailsRouteBuilder";
// oei
import LicenceListController from "./oei/licenceList/components/LicenceListController";
import LicenceDetailsController from "./oei/licenceDetails/components/LicenceDetailsController";
// my joint Authority
import JointAuthorityController from "./userManagement/jointAuthorityDetails/components/JointAuthorityController";
// user management
import RequestListController from "./userManagement/requestList/components/RequestListController";
import UserListController from "./userManagement/userList/components/UserListController";
import UserDetailsController from "./userManagement/userDetails/components/UserDetailsController";
import RequestDetailsController from "./userManagement/requestDetails/components/RequestDetailsController";
// my account
import RequestAccessController from "./account/requestAccess/components/RequestAccessController";
import MyDetailsController from "./account/myDetails/components/MyDetailsController";

const routeBuilder: RouteBuilder = (isSignedIn: boolean) => {
    // useful for cheap feature switching
    const isProductionEnvironment = GlobalHelpers.isProductionEnvironment(); // eslint-disable-line @typescript-eslint/no-unused-vars
    let routes = new Array<IRouteItem>();

    // not authenticated? don't register routes
    if (!isSignedIn) return routes;

    // home
    routes.push({ path: "/JointAuthority/Home/Dashboard", exact: true, element: <DashboardController /> });

    // application
    routes.push({ path: "/JointAuthority/Opggs/ApplicationList", exact: true, element: <ApplicationListController /> });

    // titles
    routes.push({ path: "/JointAuthority/Opggs/TitleList", exact: true, element: <TitleListController /> });

    // licence
    routes.push({ path: "/JointAuthority/Oei/LicenceList", exact: true, element: <LicenceListController /> });
    routes.push({ path: "/JointAuthority/Oei/LicenceDetails/:id/:tab?", exact: true, element: <LicenceDetailsController /> });

    // user management - View My Requests
    routes.push({ path: "/JointAuthority/User/RequestList", exact: true, element: <RequestListController /> });
    routes.push({ path: "/JointAuthority/User/RequestDetails/:type/:id", exact: true, element: <RequestDetailsController /> });
    // user management - My Joint Authority
    routes.push({ path: "/JointAuthority/User/MyJointAuthority", exact: true, element: <JointAuthorityController /> });
    routes.push({ path: "/JointAuthority/User/UserList", exact: true, element: <UserListController /> });
    routes.push({ path: "/JointAuthority/User/UserDetails/:id", exact: true, element: <UserDetailsController /> });

    // my account
    routes.push({ path: "/JointAuthority/Account/RequestAccess", exact: true, element: <RequestAccessController /> });
    routes.push({ path: "/JointAuthority/Account/MyDetails", exact: true, element: <MyDetailsController /> });

    // application details
    routes = routes.concat(applicationDetailsRouteBuilder(isSignedIn));
    return routes;
};

export default routeBuilder;
