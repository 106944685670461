import msalAuthService from "../infrastructure/msalAuthService";
import * as UriHelper from "./uriHelper";

// the Fetch class provides low level capabilities for making service calls
// note these do not attempt to retry, only to avoid complexity
// these calls are used in very specific edge cases within the system

export class Fetch {
    public async postBlob(relativeUri: string, requestPayload: Uint8Array, correlationId: string): Promise<any> {
        // get the access token
        const accessToken = await this._getAccessToken();

        // build the URI
        const baseUri = UriHelper.getServicesUri();
        const uri = baseUri + "/" + relativeUri;

        // fetch!
        const response = await fetch(uri, {
            method: "POST",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
                "Content-Type": "application/octet-stream",
                "Content-Length": `${requestPayload.length}`,
                "Correlation-Id": correlationId,
            },
            body: requestPayload,
        });

        // assert success
        if (!response.ok) throw new Error("post failed.");

        // return response
        return await response.json();
    }

    public async post(relativeUri: string, request: any, correlationId: string): Promise<any> {
        // get the access token
        const accessToken = await this._getAccessToken();

        // build the URI
        const baseUri = UriHelper.getServicesUri();
        const uri = baseUri + "/" + relativeUri;

        // build the request
        const requestPayload = JSON.stringify(request);

        // fetch!
        const response = await fetch(uri, {
            method: "POST",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "application/json",
                "Content-Type": "application/json",
                "Correlation-Id": correlationId,
            },
            body: requestPayload,
        });

        // assert success
        if (!response.ok) throw new Error("post failed.");

        // return response
        return await response.json();
    }

    public async getTokenWithQuery(relativeUri: string, query: string): Promise<void> {
        // get the access token
        const accessToken = await this._getAccessToken();

        // build the URI
        const baseUri = UriHelper.getServicesUri();
        let uri = baseUri + "/" + relativeUri + "?" + query;

        // fetch!
        // - credentials: include is needed to retrieve a cookie
        // - this brings very special CORS requirements!
        const response = await fetch(uri, {
            method: "GET",
            cache: "no-cache",
            credentials: "include",
            headers: {
                Authorization: "Bearer " + accessToken,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });

        // assert success
        if (!response.ok) throw new Error("getTokenWithQuery failed.");

        // read the response, although it will be empty
        // this ensures the response is processed and the cookie is loaded by the browser
        await response.text();
    }

    private async _getAccessToken() {
        // the msal auth service caches the token, and is surprisingly fast on fetch
        // the danger of caching it locally is it may expire, so best to get it from the service as it will know when to refresh the token
        return await msalAuthService.getAccessToken();
    }
}
