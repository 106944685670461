// framework
import { useEffect, useState } from "react";
import { connect } from "react-redux";
// redux
import { IRootReduxState } from "../../../../../infrastructure/reduxRootReducer";
import * as Models from "../models/models";
import * as Actions from "../redux/actions";
// common
import BodyLayout from "../../../../../common/shell/BodyLayoutController";
import StatusMessagesAlertsView from "../../../../../common/alerts/StatusMessagesAlertsView";
import { Level1ItemTypeEnum } from "../../../../../shell/layout/navigationItemTypes";
import { KendoSingleSmallFileUpload } from "../../../../../common/fileUpload/KendoSingleSmallFileUpload";
import DownloadLinkView from "../../../../../common/externalLink/DownloadLinkView";
import { IAdvancedSearchFilter } from "../../../../../common/advancedSearch/models";
import SearchBarView from "../../../../../common/advancedSearch/SearchBarView";
import SimpleAlertView from "../../../../../common/simpleAlerts/SimpleAlertView";
import * as documentHelper from "../../../../../common/documentHelper";
import * as toastHelper from "../../../../../common/toastHelper";
import { ValidationVisibilityEnum } from "../../../../../common/validation/ValidationModel";
import validator from "../models/validator";
import * as GlobalHelpers from "../../../../../common/GlobalHelpers";
// views
import AttachmentListGridView from "./views/AttachmentListGridView";
import BulkDeleteDialog from "../controls/bulkDelete/components/Controller";
//Kendo
import { DropDownButton, DropDownButtonItem } from "@progress/kendo-react-buttons";
import { caretAltDownIcon } from "@progress/kendo-svg-icons";
import ValidationMessageView from "../../../../../common/validation/ValidationMessageView";

interface IProps extends Actions.IActionFactory {
    rootViewModel: Models.IRootViewModel;
}

function Controller(props: IProps): JSX.Element {
    const vm = props.rootViewModel;
    const actions: Actions.IActionFactory = props;

    const [showBulkDelete, setShowBulkDelete] = useState(false);
    const [showBulkImportView, setShowBulkImportView] = useState(false);
    const [enableImportButton, setEnableImportButton] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [showBatchDownload, setShowBatchDownload] = useState(false);
    const [validationVisibility, setValidationVisibility] = useState(ValidationVisibilityEnum.Messages);
    const validation = validator(vm.attachments, validationVisibility);

    // on mount
    useEffect(() => {
        actions.initialiseRequested();
        return function () {
            actions.clearRequested();
            setEnableImportButton(false);
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onSearchSubmit = (value: string, advancedFilter: IAdvancedSearchFilter) => {
        actions.viewChanged(vm.changeSearchFilter(value, advancedFilter));
        actions.searchRequested();
    };

    const onGridClear = () => {
        // note that this also triggers a grid state reset
        actions.viewChanged(vm.clearAllSearchFilters());
        actions.searchRequested();
    };

    const handleDropDownButtonItemClick = (event: any) => {
        if (event.item.text === "All Attachments") {
            actions.exportAttachmentFile();
        }

        if (event.item.text === "Search Results") {
            actions.exportAttachmentFile(vm.searchText, vm.advancedSearchFilter);
        }
    };

    function onImportClicked() {
        actions.uploadAttachmentFile(vm);
        actions.viewChanged(vm.refreshImportAttachmentFile());
        setEnableImportButton(false);
    }

    function onCancelClicked() {
        setShowBulkImportView(false);
        actions.viewChanged(vm.refreshStatusMessages().refreshImportAttachmentFile());
    }

    function onAttachmentFileChange(fileName: string | undefined, content?: string | undefined) {
        actions.viewChanged(vm.refreshImportAttachmentFile({ fileName: fileName!, fileContent: content! }));
        setEnableImportButton(true);
    }

    function onBatchDownloadHandle() {
        if (!validation.isValid()) {
            setValidationVisibility(ValidationVisibilityEnum.MessagesAndAdorners);
            toastHelper.showValidationErrorNotification();
            return;
        }

        documentHelper.multipleDownloads(
            vm.attachments
                .filter((attachment) => attachment.isSelected)
                .map((attachment) => {
                    return { url: attachment.documentLink, fileName: attachment.fileName };
                })
        );

        cancelBatchDownloads();
    }

    function cancelBatchDownloads() {
        setShowBatchDownload(false);
        actions.viewChanged(vm.clearSelectedAttachments());
    }

    const searchTextDescription =
        "The Attachments search returns records where the word or phrase is contained in either Item ID (formerly Store Barcode/Archive ID), Name, File Name, Document Link or Bulk Import ID.";
    const advancedSearchDescription =
        "The Advanced search filters are only applied when set, and returns records for a defined search from Boreholes, Acquisitions, Projects, ENOs (Wells, Boreholes, Surveys, Acquisitions, Reprocessing and Projects) and/or Item IDs.";

    return (
        <>
            {vm.advancedSearchFilter && !showBulkImportView && (
                <BodyLayout title="Search Attachments" selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_Attachments}>
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />

                    {!showBatchDownload && (
                        <SearchBarView
                            searchText={vm.searchText}
                            searchTextDescription={searchTextDescription}
                            advancedSearchFilter={vm.advancedSearchFilter!}
                            advancedSearchDescription={advancedSearchDescription}
                            advancedSearchReferenceData={vm.advancedSearchReferenceData}
                            onSearchSubmit={onSearchSubmit}
                            onGridClear={onGridClear}
                            showAdvanced={showAdvanced}
                            setShowAdvanced={setShowAdvanced}
                        />
                    )}
                    {vm.isResultSetLimited && vm.viewState !== Models.ViewStateEnum.Searching && (
                        <SimpleAlertView
                            alertType="warning"
                            message={`For performance reasons, only the first ${GlobalHelpers.toNoptaNumberString(
                                vm.attachments.length,
                                "n0"
                            )} records have been returned. Please adjust your search criteria to locate relevant data.`}
                            dismissable={true}
                        />
                    )}
                    {vm.viewState !== Models.ViewStateEnum.Searching && (
                        <AttachmentListGridView
                            vm={vm}
                            showBatchDownload={showBatchDownload}
                            onSelectionChanged={(attachment) => {
                                actions.viewChanged(vm.refreshSelectedAttachment(attachment));
                            }}
                        />
                    )}
                    {showBatchDownload && <ValidationMessageView name="batchDownload.ItemSelected" validationModel={validation} />}
                    <div className="container-fluid p-2 form">
                        <div className="mt-3">
                            <>
                                <div className="row">
                                    {!showBatchDownload && vm.viewState !== Models.ViewStateEnum.Searching && (
                                        <div className="col-auto">
                                            <button type="button" className="btn btn-primary" onClick={() => setShowBatchDownload(true)} title="Switch to Batch download view">
                                                Batch Download
                                            </button>
                                        </div>
                                    )}

                                    {showBatchDownload && (
                                        <>
                                            <div className="col-auto">
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={() => onBatchDownloadHandle()}
                                                    title="Batch download selected attachment documents"
                                                    disabled={vm.attachments.filter((a) => a.isSelected).length === 0}
                                                >
                                                    Download
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button type="button" className="btn btn-secondary" onClick={() => cancelBatchDownloads()} title="Switch out of batch download view">
                                                    Cancel
                                                </button>
                                            </div>
                                        </>
                                    )}

                                    {!showBatchDownload && vm.viewState !== Models.ViewStateEnum.Searching && (
                                        <>
                                            <div className="col-auto">
                                                <button type="button" className="btn btn-primary" onClick={() => setShowBulkImportView(true)} disabled={showBatchDownload}>
                                                    Bulk Import
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <button type="button" className="btn btn-primary" onClick={() => setShowBulkDelete(true)} disabled={showBatchDownload}>
                                                    Delete Bulk Import
                                                </button>
                                            </div>
                                            <div className="col-auto">
                                                <DropDownButton
                                                    text="Export to Excel"
                                                    themeColor={"primary"}
                                                    svgIcon={caretAltDownIcon}
                                                    onItemClick={handleDropDownButtonItemClick}
                                                    disabled={showBatchDownload}
                                                >
                                                    <DropDownButtonItem text="All Attachments"></DropDownButtonItem>
                                                    <DropDownButtonItem text="Search Results" disabled={!vm.searchText && !showAdvanced}></DropDownButtonItem>
                                                </DropDownButton>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </>
                        </div>
                    </div>
                    {showBulkDelete && (
                        <BulkDeleteDialog
                            onDelete={() => {
                                setShowBulkDelete(false);
                                actions.initialiseRequested();
                            }}
                            onCancel={() => setShowBulkDelete(false)}
                        />
                    )}
                </BodyLayout>
            )}
            {showBulkImportView && (
                <BodyLayout title="Attachment Bulk Import" selectedLhsNavItem={Level1ItemTypeEnum.GeoscienceAustralia_ReportsAndData_Attachments}>
                    <p>
                        Use the template{" "}
                        <DownloadLinkView href="/AttachmentBulkImport.xlsx" title="Click to download the document.">
                            AttachmentBulkImport.xlsx
                        </DownloadLinkView>
                    </p>
                    <StatusMessagesAlertsView statusMessages={vm.statusMessages} />

                    <h5>File Upload</h5>

                    <KendoSingleSmallFileUpload onFileValidlySelected={onAttachmentFileChange} allowedExtensions={[".xlsx"]} maxFileSizeMb={20} />

                    <div className="container-fluid p-2 form">
                        <div className="mt-3">
                            <>
                                <div className="row">
                                    <div className="col-auto">
                                        <button type="button" className="btn btn-outline-primary" disabled={!enableImportButton} onClick={() => onImportClicked()}>
                                            Import
                                        </button>
                                        <button type="button" className="btn btn-outline-secondary m-2" onClick={() => onCancelClicked()}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </BodyLayout>
            )}
        </>
    );
}

// connect redux
export default connect((state: IRootReduxState) => ({ rootViewModel: state.GeoscienceAustralia_WellsBoreholes_AttachmentList }), Actions.actionFactory)(Controller);
