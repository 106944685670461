import { takeEvery } from "redux-saga/effects";
import * as Actions from "./actions";

import initialise from "./sagas/initialise";
import search from "./sagas/search";
import batchUpdate from "./sagas/batchUpdate";
import exportReports from "./sagas/exportSurveyData";

export default function* watcherSaga() {
    yield takeEvery(Actions.actions.initialiseRequested, initialise);
    yield takeEvery(Actions.actions.searchRequested, search);
    yield takeEvery(Actions.actions.batchUpdateRequested, batchUpdate);
    yield takeEvery(Actions.actions.exportRequested, exportReports);
}
