// views
import CommonNavBar from "../../../../common/CommonNavBarView";
// common
import * as geoscienceAustraliaRouteHelper from "../../../../geoscienceAustraliaRouteHelper";

export enum TabsEnum {
    Details,
    DataMonitoring,
    Reports,
    Attachments,
    SurveyData,
}

export interface IProps {
    surveyId: number;
    acquisitionId: number;
    activeNavItem: TabsEnum;
}

export default function NavBarView(props: IProps): JSX.Element {
    return (
        <CommonNavBar
            activeNavItemKey={props.activeNavItem}
            navItems={[
                {
                    key: TabsEnum.Details,
                    title: "Details",
                    url: geoscienceAustraliaRouteHelper.acquisitionDetails(props.surveyId, props.acquisitionId),
                },
                {
                    key: TabsEnum.DataMonitoring,
                    title: "Data Monitoring",
                    url: geoscienceAustraliaRouteHelper.acquisitionDataMonitoring(props.surveyId, props.acquisitionId),
                },
                {
                    key: TabsEnum.Reports,
                    title: "Reports",
                    url: geoscienceAustraliaRouteHelper.acquisitionReports(props.surveyId, props.acquisitionId),
                },
                {
                    key: TabsEnum.Attachments,
                    title: "Attachments",
                    url: geoscienceAustraliaRouteHelper.acquisitionAttachments(props.surveyId, props.acquisitionId),
                },
                {
                    key: TabsEnum.SurveyData,
                    title: "Survey Data",
                    url: geoscienceAustraliaRouteHelper.acquisitionSurveyData(props.surveyId, props.acquisitionId),
                },
            ]}
        />
    );
}
